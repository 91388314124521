import { Image } from '@ui/atoms/index';
import { useRouter } from 'next/router';
import Logo from '../../public/images/logo.svg';
import MainTenancePageImage from '../../public/images/maintenance-page.svg';
import RetryIcon from '../../public/images/redo.svg';
import { careEmail } from '../../helpers/constants';
import { Button } from '../../atoms/design-system';
import texts from './en.json';

const FallbackUI = ({ statusCode }) => {
    const router = useRouter();

    return (
        <div className="w-full bg-primary-50 min-h-screen relative z-50">
            <div className="min-h-screen py-10 flex flex-col items-center justify-between bg-basicWhite px-5">
                {/* Logo */}
                <div className="w-28 flex-1">
                    <Image
                        className="h-8 cursor-pointer"
                        onClick={() => router.push('/')}
                        src={Logo.src}
                    />
                </div>

                <div className="text-center flex-1">
                    <h2 className="md:tracking-tight font-heading text-xl md:text-32px font-semibold h4 px-6 md:h2 mb-3 text-primary-500">
                        {statusCode === 404
                            ? texts?.NotFoundError
                            : texts?.SomethingWentWrongError}
                    </h2>

                    <h6 className="font-paragraph text-sm md:text-base font-normal text-gray-700 mb-10">
                        {statusCode === 404
                            ? texts?.NotFoundErrorMessage
                            : texts?.SomethingWentWrongErrorMessage}
                    </h6>
                    <div className="mb-12 flex justify-center align-center">
                        <Image src={MainTenancePageImage.src} />
                    </div>

                    <div className="flex flex-col justify-center items-center mb-16">
                        <Button
                            onClick={() => {
                                if (statusCode === 404) {
                                    router.push('/');
                                } else {
                                    router.reload();
                                }
                            }}
                            buttonType="primary"
                            prefixComponent={
                                statusCode !== 404 ? (
                                    <Image
                                        className="mr-2"
                                        src={RetryIcon.src}
                                    />
                                ) : null
                            }
                            buttonText={
                                statusCode === 404 ? texts?.GoToHomePage : texts?.Retry
                            }
                        />
                    </div>

                    <div className="bottom-content flex flex-col items-center justify-center">
                        <p className="h5 text-sm md:text-lg text-basicBlack font-medium font-paragraph">
                            {texts?.NeedHelpMessage}{' '}
                            <span
                                className="cursor-pointer text-secondary-600"
                                onClick={(e) => {
                                    window.location = `mailto:${careEmail}`;
                                    e.preventDefault();
                                }}
                            >
                                {careEmail}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FallbackUI;
